<template>
  <span class="text-nowrap">
    <feather-icon
        :id="`row-${data.item.id}-images`"
        icon="ImageIcon"
        size="16"
        @click="$router.push({ name: routerName, params: { id: data.item.id }})"/>
  </span>
</template>

<script>
import {BTooltip} from "bootstrap-vue";

export default {
  name: "ImagesButtonTable",
  components: {BTooltip},
  props: ["data", "routerName", "tooltipNotVisible"]
}
</script>
